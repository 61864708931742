import React from "react";
import { Navigate } from "react-router-dom";
import async from "./components/Async";
// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Page404 from "./pages/auth/Page404";
import AppVersion from "./components/auth/AppVersion";

const Monitoring = async(() => import("./pages/dashboard/monitoring"));
const Tlgrm = async(() => import("./pages/tlgrm"));
const YPXSearch = async(() => import("./pages/search"));
const Tadbirkor = async(() => import("./pages/settings/tadbirkor"));
const DevicesDashboard = async(() => import("./pages/dashboard/devices"));
const ObjectInside = async(() => import("./pages/settings/objects"));
const Map = async(() => import("./pages/map"));
const Live = async(() => import("./pages/dashboard/live"));
const Violations = async(() => import("./pages/dashboard/violations"));
const Search = async(() => import("./pages/search"));
const Verification = async(() => import("./pages/verification"));
const Settings = async(() => import("./pages/settings/groups"));
const Spravochnik = async(() => import("./pages/settings/spravochnik"));
const Appmanager = async(() => import("./pages/settings/appmanager"));
const Filemanager = async(() => import("./pages/settings/filemanager"));
const Apps = async(() => import("./pages/settings/appmanager/version/Index"));
const MainTain = async(() => import("./pages/settings/maintain/index"));
const ComputerCamera = async(() => import("./pages/settings/cameras"));
const Staff = async(() => import("./pages/settings/staff"));
const Control = async(() => import("./pages/ypx/control"));
const ControllerUser = async(() => import("./pages/settings/controllerUsers"));
const Operators = async(() => import("./pages/dashboard/operators"));
const ManageOperators = async(() => import("./pages/settings/manageOperators"));
const ReportByViolations = async(() =>
  import("./pages/report/reportByViolations")
);
const ReportByOperator = async(() => import("./pages/report/reportByOperator"));
const ReportByCamera = async(() =>
  import("./pages/report/reportByCamera/index.js")
);
const ReportDisabledDevices = async(() =>
  import("./pages/report/reportDisabledDevices/index.js")
);
const Notification = async(() => import("./components/notification/index.js"));
const Snapshots = async(() => import("./pages/dashboard/snapshots/index.js"));
const main = [
  {
    path: "dashboard",
    element: <Violations />,
  },
  { path: "devices", element: <Live /> },
  { path: "operators-stat", element: <Operators /> },
  { path: "manage-operators", element: <ManageOperators /> },
  { path: "devices-stat", element: <DevicesDashboard /> },
  { path: "report-by-violations", element: <ReportByViolations /> },
  { path: "report-disabled-devices", element: <ReportDisabledDevices /> },
  { path: "report-by-operator", element: <ReportByOperator /> },
  { path: "report-by-camera", element: <ReportByCamera /> },
  { path: "search", element: <Search /> },
  { path: "verification", element: <Verification /> },
  { path: "map", element: <Map map={true} /> },
  { path: "settings", element: <Settings /> },
  { path: "directory", element: <Spravochnik /> },
  { path: "appmanager", element: <Appmanager /> },
  { path: "filemanager", element: <Filemanager /> },
  { path: "appmanager/:name", element: <Apps /> },
  { path: "maintain", element: <MainTain /> },
  { path: "settings/tadbirkor", element: <Tadbirkor /> },
  { path: "settings/tadbirkor/object", element: <ObjectInside /> },
  { path: "monitoring", element: <Monitoring /> },
  { path: "notification", element: <Notification /> },
  { path: "personalNotification", element: <Notification /> },
  { path: "snapshots", element: <Snapshots /> },

  {
    path: "settings/tadbirkor/object/computer-camera",
    element: <ComputerCamera />,
  },
  {
    path: "staff",
    element: <Staff />,
  },
  {
    path: "control",
    element: <Control />,
  },
  {
    path: "search-ypx",
    element: <YPXSearch />,
  },
  {
    path: "controller-users",
    element: <ControllerUser />,
  },
  {
    path: "404",
    element: <h1>Администраторга мурожаат қилинг</h1>,
  },
  {
    path: "tlgrm",
    element: <Tlgrm />,
  },
  {
    path: "/",
    element: <Navigate to={"*"} />,
  },
];

const auth = [
  { path: "sign-in", element: <SignIn /> },
  { path: "sign-up", element: <SignUp /> },
  { path: "*", element: <Page404 /> },
  { path: "appversion", element: <AppVersion /> },
];

// Roles

const tadbirkor = [
  "search",
  "devices",
  "report-by-violations",
  "monitoring",
  "personalNotification",
  "snapshots",
  "control",
];

const admin = [
  "dashboard",
  "search",
  "report-by-violations",
  "report-by-operator",
  "report-disabled-devices",
  "report-by-camera",
  "snapshots",
  "verification",
  "map",
  "/",
  "settings",
  "directory",
  "appmanager",
  "appmanager/:name",
  "maintain",
  "filemanager",
  "settings/tadbirkor",
  "settings/tadbirkor/object",
  "settings/tadbirkor/object/computer-camera",
  "staff",
  "control",
  "search-ypx",
  "controller-users",
  "devices",
  "operators-stat",
  "devices-stat",
  "manage-operators",
  "monitoring",
  "notification",
  "personalNotification",
];

const operator = ["verification", "/"];

const ypx = (verify, access, search, report) => {
  const routes = [];
  access && routes.push("control");
  verify && routes.push("verification");
  search && routes.push("search-ypx");
  report && routes.push("report-by-violations");
  return routes.length === 0 ? ["404", "/"] : [...routes, "/"];
};

const moderator = [
  "dashboard",
  "operators-stat",
  "search",
  "staff",
  "verification",
  "manage-operators",
  "/",
];

const boss = [
  "dashboard",
  "operators-stat",
  "devices",
  "devices-stat",
  "monitoring",
  "snapshots",
  "report-by-violations",
  "report-disabled-devices",
  "report-by-camera",
  "search",
  "map",
  "/",
];
const engineer = [
  "dashboard",
  "devices",
  "report-by-violations",
  "report-disabled-devices",
  "report-by-camera",
  "devices-stat",
  "snapshots",
  "search",
  "settings",
  "monitoring",
  "settings/tadbirkor",
  "settings/tadbirkor/object",
  "settings/tadbirkor/object/computer-camera",
  "map",
  "operators-stat",
  "control",
  "/",
];
const worker = ["tlgrm"];
const notAuth = ["sign-in", "sign-up", "appversion", "/"];

export const checkPermission = (userPermissionArray, path) => {
  if (!Array.isArray(userPermissionArray) && userPermissionArray.length === 0)
    return false;
  return userPermissionArray.includes(path);
};

export const childrenRoutes = { main, auth };

export const getUserType = (user) => {
  if (!user) return notAuth;
  if (user?.group_id === -6) return worker;
  if (user?.group_id === -5) return engineer;
  if (user?.group_id === -4) return boss;
  if (user?.group_id === -3) return moderator;
  if (user?.group_id === -2)
    return ypx(user?.verify, user?.access, user?.search, user?.report);
  if (user?.group_id === -1) return operator;
  if (user?.group_id === 0) return admin;
  if (user?.group_id > 0) return tadbirkor;
  else return notAuth;
};
